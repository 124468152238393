<template>
  <UiIcon
    v-if="icon"
    class="mr-4 invert"
    :name="props.icon"
    :width="70"
    :height="70"
    :lazy-img="true"
  />
  <div>
    <p
      v-if="props.title"
      class="text-base font-bold"
    >
      {{ props.title }}
    </p>
    <HtmlParser
      v-if="props?.description"
      class="text-base font-normal"
      :html="props.description"
    />
  </div>
</template>

<script setup lang="ts">
import UiIcon from '../../UiIcon/UiIcon.vue'

interface UiIconBoxItem {
  icon: string
  title: string
  description: string
}
const props = defineProps<UiIconBoxItem>()
</script>
